import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../../services/authentication.service';

@Component({
  selector: 'app-side-stepper',
  templateUrl: './side-stepper.component.html',
  styleUrl: './side-stepper.component.less'
})
export class SideStepperComponent {
  profileuUrl : string = '';
  @Input() activeStep : string = '';
  pendingStep: string | null = null;
  usersInitial: string = '';
  // activeStep : string = 'offer'
  steps : any = [
    // { key: 'dashboad', value: 'Dashboard', img : 'assets/images/icons/dashboard.png' },
    { key: 'locations', value: 'Location', img : 'assets/images/icons/location_pin.png' },
    { key: 'offers', value: 'Offer', img : 'assets/images/icons/award.png' },
    { key: 'profile', value: 'Profile', img : 'assets/images/icons/profile_tag.png' },
  ]
  activeTooltipKey: string | null = null;

  constructor(
    private router: Router,
    private authenticationService : AuthenticationService
  ) {
  }
  ngOnInit(): void {
    this.getUserInitial();
  }
  getUserInitial(){
    if((this.authenticationService.currentUser.value.user.first_name && this.authenticationService.currentUser.value.user.first_name!=='') || (this.authenticationService.currentUser.value.user.last_name && this.authenticationService.currentUser.value.user.last_name!=='')){
      this.usersInitial = 
      (this.authenticationService.currentUser.value.user.first_name?.charAt(0).toUpperCase() || '') +
      (this.authenticationService.currentUser.value.user.last_name?.charAt(0).toUpperCase() || '');  
    }else{
      this.profileuUrl='assets/images/icons/header_person_logo.png';
    }
  }
  navigate(stepKey: string) {
    this.pendingStep = stepKey; // Set the pending step

    // Assuming your `canDeactivate` guard has been properly integrated in the routing
    this.router.navigate([stepKey]).then((navigationSuccess: boolean) => {
      if (navigationSuccess) {
        // Update active step only if navigation was successful
        this.activeStep = this.pendingStep ?? '';
      }
      // Reset pendingStep whether navigation was successful or not
      this.pendingStep = null;
    });
  }

  showTooltip(event: MouseEvent, value: string) {
    this.activeTooltipKey = this.steps.find((step:any) => step.value === value)?.key || null;
  }

  hideTooltip() {
    this.activeTooltipKey = null;
  }
}
